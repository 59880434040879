import React from 'react'
import styled from 'styled-components'

function GDPR() {
  return (
    <Container>
      <h3>Introduktion</h3>
      <br />
      24Food är personuppgiftsansvarig och behandlar personuppgifter som
      registreras hos 24Food i samband med användning av applikationen. De
      personuppgifter som 24Food registrerar vid inloggning med mobiltbankID är
      namn och personnummer. Vid skapandet av konto registrerar 24Food namn,
      Id-handling och telefonnummer. <br />
      <br />
      <br />
      <h3>Personuppgiftsansvarig</h3>
      <br />
      24Food i Örebro AB Org. nummer: 559081–7788, är ansvarig för behandlingen
      av personuppgifter och ansvarar för att behandlingen av dina
      personuppgifter sker i enlighet med dataskyddslagstifning. <br />
      <br />
      Dina personuppgifter används av oss i följande syfte:
      <br />
      <br />
      För att ge dig möjligheten att handla i en 24Food butik.
      <br />
      För att kunna utveckla tjänsten.
      <br />
      För att fullgöra rättsliga förpliktelser
      <br />
      För att kunna anpassa information och erbjudanden.
      <br />
      <br />
      24Food kan komma att lämna ut personuppgifter till andra samarbetande
      företag för att kunna utveckla tjänsten och förebygga brottslighet. 24Food
      kommer inte att sälja dina personuppgifter till extern part. 24Food har
      vidtagit säkerhetsåtgärder för att förhindra att dina personuppgifter
      skall komma ut till externa parter. Förfrågningar om 24Foods behandling av
      dina personuppgifter sker enligt kontaktuppgifterna nedan. Genom att
      acceptera villkoren samtycker du till behandlingen av personuppgifter som
      beskrivs i texten. 24Food kommer vid en av avinstallation av applikationen
      fortfarande lagra personlig information.
      <br />
      <br />
      <br />
      <h3>Marknadsföring</h3>
      <br />
      Applikationen används som en informationskanal för 24Foods kunder
      varigenom 24food kan skicka information och erbjudanden avseende sina och
      sina samarbetspartners produkter och tjänster. Genom att acceptera dessa
      villkor godkänner du att 24food skickar marknadsföring till dig.
      <br />
      <br />
      <br />
      <h3>Positionering</h3>
      <br />
      Applikationen kräver att du som användare samtycker till att du genom din
      enhet positioneras för vissa funktioner skall kunna användas. Du har
      möjlighet att godkänna eller neka detta. Ifall du inte vill att 24Food
      skall använda sig av din platsinformation kan du stänga av denna i
      applikationen. Applikationen kommer då inte fungera.
      <br />
      <br />
      <br />
      <h3>Rättigheter enligt GDPR:</h3>
      <br />
      Dataskyddsförordningen ger registrerade personer en mängd rättigheter som
      din organisation (eller dina system) måste kunna hantera. De viktigaste
      rättigheterna handlar om kontroll över vilken information som får lagras
      och hur informationen får användas.
      <br />
      <br />
      Registrerades personer har enligt Dataskyddsförordningen GDPR rätt till:
      <br />
      <br />
      Information (om behandlingen)
      <br />
      Tillgång (till registrerad information)
      <br />
      Rättelse (av grunduppgifter)
      <br />
      Radering (rätt att bli struken, om det inte längre finns behov av
      uppgifterna)
      <br />
      Begränsning av behandling ("frys mina uppgifter!")
      <br />
      Dataportabilitet (rätt att få ut uppgifter på maskinläsbart format)
      <br />
      Att göra invändningar (=rätt att ifrågasätta användning av uppgifter.
      Exempel: rätt att slippa marknadsföring.)
      <br />
      Rättigheter kopplade till automatiserat beslutsfattande (framförallt: rätt
      att få information om automatiserad behandling)
      <br />
      <br />
      <br />
      <h3>Övrigt</h3>
      <br />
      Applikationen använder också olika typer av tjänster från Firebase. Dom
      tjänster 24food använder från Firebase är:
      <br />
      <br />
      Google analytics
      <br />
      Authentication
      <br />
      Firestore database
      <br />
      Realtime database
      <br />
      Storage
      <br />
      Functions
      <br />
      <br />
      Appen samlar också in kraschdata när någor fel sker i appen.
    </Container>
  )
}

export default GDPR

const Container = styled.div`
  box-sizing: border-box;
  max-width: 900px;
  margin: 100px auto;
  padding: 80px;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);

  @media (max-width: 900px) {
    margin: 80px auto 0;
    padding: 20px;
  }
  @media (max-width: 769px) {
    margin: 60px auto 0;
  }
`
