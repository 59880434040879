import React, { useRef } from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'

import { MainTextHolder } from './Utils'
import { useInView } from 'react-intersection-observer'
import {
  IoStorefrontOutline,
  IoPricetagsOutline,
  IoBarcodeOutline,
} from 'react-icons/io5'
import {
  BiWorld,
  BiSupport,
  BiStore,
  BiBuilding,
  BiUserCircle,
} from 'react-icons/bi'
import {
  MdSecurity,
  MdMoneyOff,
  MdOutlineLocalShipping,
  MdOutlineStore,
} from 'react-icons/md'
import { RiLightbulbFlashLine } from 'react-icons/ri'
import PageAnchor from './PageAnchor'

export default function Franchise() {
  const controls = useAnimation()
  const { ref, inView } = useInView()
  const animated = useRef<boolean>(false)

  useEffect(() => {
    if (inView && !animated.current) {
      controls.start(i => ({
        opacity: 1,
        y: 0,
        transition: { delay: i * 0.3, duration: 0.8 },
      }))
      animated.current = true
    } else {
      controls.start({
        opacity: 1,
        y: 0,
        transition: {
          delay: 0,
          duration: 0,
        },
      })
    }
  }, [controls, inView])

  const arrItems = [
    {
      text:
        'Möjlighet till att hyra butik av oss. Vilket ger dig som samarbetspartner möjlighet att öppna fler butiker än om du skulle köpa en butik.',
      icon: <IoStorefrontOutline size="60px" />,
    },
    {
      text: 'Lägst priser på marknaden.',
      icon: <IoPricetagsOutline size="60px" />,
    },
    {
      text: 'Möjlighet att handla i butiken som utländsk medborgare.',
      icon: <BiWorld size="60px" />,
    },
    {
      text: 'Möjlighet att handla i butiken med personlig tagg och kod.',
      icon: <IoBarcodeOutline size="60px" />,
    },
    {
      text: 'Marknadens bästa säkerhetssystem med dygnet runt övervakning.',
      icon: <MdSecurity size="60px" />,
    },
    { text: 'Inga franchiseavigfter.', icon: <MdMoneyOff size="60px" /> },
    {
      text: 'Dygnet runt support via telefon för kund och samarbetspartner.',
      icon: <BiSupport size="60px" />,
    },
    {
      text:
        'Leverantör som erbjuder samarbetspartnern att handla varor som ligger 10% - 90% under snittet hos Sveriges fem största leverantörer i dagligvaruhandeln.',
      icon: <MdOutlineLocalShipping size="60px" />,
    },
    {
      text:
        'Ständigt utvecklande av varumärket och kedjan i helhet. Under 2022 inleder 24Food tre unika samarbeten för marknaden.',
      icon: <RiLightbulbFlashLine size="60px" />,
    },
  ]

  return (
    <PageAnchor id="franchise">
      <div style={{ backgroundColor: '#111' }}>
        <StyledMainTextHolder>
          <h3>Vägen till öppning</h3>
          <p>
            Vägen till en 24Food behöver inte vara så lång som man kan tro.
            Nedan ser du en checklista. Har du en befintlig butik krävs det
            enbart att ett kontrakt skrivs, kort därefter kommer vi och
            installerar din utrustning och utbildar dig.
          </p>
          <StoreOpeningSteps>
            <li>
              Första kontakten, här kommer vi överens om vilken butiksmodell som
              passar, samt diskuterar orten/platsen du vill ställa denna.{' '}
            </li>
            <li>
              Kontakta kommun/privatmarkägare för att få mark till butiken.
              Privata markägare är oftast enklast. 1500kr per månad är det
              maximala beloppet för markhyra.
            </li>
            <li>
              När butiksmodellen är vald och platsen godkänd så tillhandahåller
              vi ritningar övriga bygglovs handlingar. Här skriver vi kontrakt
              som säger att hyresperioden påbörjas när butiken öppnats.{' '}
            </li>
            <li>
              När bygglovet är godkänt så förbereder vi för leverans av butik
              inom 7 veckor.{' '}
            </li>
            <li>
              Runt perioden när bygglovet blivit godkänt skickas uppgifter om
              etablering av livsmedelsverksamhet in till kommunen,
              kassaleverantör kontaktas, skapande av konto hos leverantörer.{' '}
            </li>
            <li>
              Butiken blir levererad och vi genomför en utbildning. 24Food
              förbereder en startorder för butiken.
            </li>
            <li>Första beställningen av två mottages till butiken.</li>
            <li>Premiär och butiken öppnas!</li>
          </StoreOpeningSteps>
          <HowItemHolder ref={ref}>
            {arrItems.map(item => {
              return (
                <HowItem
                  custom={0}
                  animate={controls}
                  initial={{ opacity: 0, y: 20 }}
                  key={uuid()}
                >
                  <ListItem text={item.text} icon={item.icon} />
                </HowItem>
              )
            })}
          </HowItemHolder>
          <p>
            Välkommen till 24Food framtidens matvarukedja. Nedan ser du våra
            olika butiksmodeller.
          </p>
          <StoreConceptHolder>
            <StoreConcept>
              <BiStore size="45px" />
              <h4>Klassisk</h4>
              <h5>11.395kr per månad</h5>
              <h6>(Inga franchiseavgifter!)</h6>
              <p>
                Vår klassiska butik är den butiksmodell vi framför allt använder
                oss av idag. Med en butiksyta på 19,1 kvm ryms ungefär 920
                artiklar av olika slag, vi brukar säga det att i stället för 12
                typer av ketchup finns två typer i den klassiska butiken.
                <br />
                <br />
                En klassisk butik är mer eller mindre som en komprimerad
                närbutik, nötfärs, fläskfilé, sju sorters färskt bröd, upp emot
                80 olika frysvaror och fyra sorter vispgrädde är med andra ord
                en självklarhet i en Klassisk butik.
                <br />
                <br />
                Butiken innehar också ett 6,6kvm stort kylrum med kylskåp med 3
                kyldörrar ut mot butiken. Detta gör att du som handlare enkelt
                kan fylla på bakifrån i butiken samtidigt som du med ett kylrum
                kan ta emot obemannade leveranser.
              </p>
              <ul>
                <li>Komplett butiksbyggnad. 3,1m bredd, 10m längd. </li>
                <li>
                  Kylrum för obemannade leveranser. Innehållande mejerikyl med
                  tre glasdörrar mot butiksytan. Hyllsystem i mejerikylen.{' '}
                </li>
                <li>Kylaggregat för kylrum.</li>
                <li>
                  Energieffektiv luftvärmepump för nedkylning och uppvärmning av
                  butik.{' '}
                </li>
                <li>
                  TVR mobile kamerasystem. Möjlighet att övervaka från mobil och
                  dator i realtid. Samt att larma och spela in. Lagring tre
                  dagar i molnet. Fyra kameror och inspelare. Tre kameror
                  inomhus och en utomhus.
                </li>
                <li>Hårdvara för dörröppning via APP.</li>
                <li>Hårdvara för öppning med tagg och passerkort.</li>
                <li>Spots utanpå.</li>
                <li>Frysskåp dubbel glasdörr 1370 x 720 x 2020 mm. </li>
                <li>
                  Displaykyl för läsk/alkohol och enklare mellanmål 1000 x 735 x
                  1990 mm.{' '}
                </li>
                <li>
                  Hylla för specerivaror, färskt bröd, Kakor, bakning osv. 2700
                  x 420 x 1800 mm.
                </li>
                <li>Hylla för läsk, godis, snacks. 2700 x 420 x 1800 mm.</li>
                <li>
                  Vägg hägnade hylla för intimvaror tillexempel 60 x 90 mm.{' '}
                </li>
                <li>Glassbox ca 700 x 700 mm. </li>
                <li>
                  Kassa hårdvara med tillhörande skattedosa och korterminal.{' '}
                </li>
                <li>Mjukvara kassasystem. </li>
                <li>
                  App med push notiser till kunder, varje enskild handlare har
                  en egen app sida där denna kan ladda upp information och
                  erbjudanden, möjlighet att lägga upp butikens alla produkter i
                  appen, möjlighet för EU och Schengen medborgare att skapa
                  konto utan svenskt bankID (kontot verifieras inom 2 minuter
                  från vårt kontor), möjlighet för kund att reklamera och
                  kontakta handlaren på dennes egna app sida, unik möjlighet att
                  köpa tobak och läkemedel i appen.{' '}
                </li>
                <li>Dygnet runt support för kund och handlare. </li>
                <li>Upphandlade rabatter hos samtliga leverantörer.</li>
                <li>20 dagars upphandlad kredit på startorder.</li>
                <li>Installation</li>
                <li>Och mycket mer… </li>
              </ul>
            </StoreConcept>
            <StoreConcept
              style={{
                marginRight: 0,
                paddingRight: 0,
                border: 0,
                paddingLeft: 40,
              }}
            >
              <BiBuilding size="45px" />
              <h4>Stor</h4>
              <h5>19.395kr per månad</h5>
              <h6>&nbsp;</h6>
              <p>
                Vår stora butik är vår nyaste butiksmodell och är dubbelt så
                stor som den klassiska. Med en butiksyta på 38,2 kvm ryms
                ungefär 2000 artiklar av olika slag.
                <br />
                <br />
                En stor butik är mer eller mindre som en närbutik, ett gediget
                utbud av allt från fruktdisk, olika typer av kött och mycket mer
                är. Stor butik är butiken som passar på den större orten eller
                orten med långt avstånd till närmaste butik.
                <br /> <br />
                Butiken innehar också ett ca 12 kvm stort kylrum med kylskåp med
                5 kyldörrar ut mot butiken. Detta gör att du som handlare enkelt
                kan fylla på bakifrån i butiken samtidigt som du med ett kylrum
                kan ta emot obemannade leveranser.
              </p>
              <ul>
                <li>Komplett butiksbyggnad. 6,2 m bredd, 10m längd. </li>
                <li>
                  Kylrum för obemannade leveranser. Innehållande mejerikyl med
                  fem glasdörrar mot butiksytan. Hyllsystem i mejerikylen.{' '}
                </li>
                <li>Kylaggregat för kylrum.</li>
                <li>
                  Energieffektiv luftvärmepump för nedkylning och uppvärmning av
                  butik.{' '}
                </li>
                <li>
                  TVR mobile kamerasystem. Möjlighet att övervaka från mobil och
                  dator i realtid. Samt att larma och spela in. Lagring tre
                  dagar i molnet. Fyra kameror och inspelare. Tre kameror
                  inomhus och en utomhus.
                </li>
                <li>Hårdvara för dörröppning via APP.</li>
                <li>Hårdvara för öppning med tagg och passerkort.</li>
                <li>Spots utanpå.</li>
                <li>2 Frysskåp dubbel glasdörr 1370 x 720 x 2020 mm. </li>
                <li>
                  1 Displaykyl för läsk/alkohol och enklare mellanmål 1000 x 735
                  x 1990 mm.{' '}
                </li>
                <li>
                  Hylla för specerivaror, färskt bröd, Kakor, bakning osv. 3400x
                  420 x 1800 mm.
                </li>
                <li>Hylla för läsk, godis, snacks. 3400 x 420 x 1800 mm.</li>
                <li>Hylla övrigt 2700 x 420 x 1800 mm. </li>
                <li>Glassbox valfri storlek. Vägg hängande hylla ovanför</li>
                <li>
                  Kassa hårdvara med tillhörande skattedosa och korterminal.{' '}
                </li>
                <li>Mjukvara kassasystem. </li>
                <li>
                  App med push notiser till kunder, varje enskild handlare har
                  en egen app sida där denna kan ladda upp information och
                  erbjudanden, möjlighet att lägga upp butikens alla produkter i
                  appen, möjlighet för EU och Schengen medborgare att skapa
                  konto utan svenskt bankID (kontot verifieras inom 2 minuter
                  från vårt kontor), möjlighet för kund att reklamera och
                  kontakta handlaren på dennes egna app sida, unik möjlighet att
                  köpa tobak och läkemedel i appen.{' '}
                </li>
                <li>Dygnet runt support för kund och handlare. </li>
                <li>Upphandlade rabatter hos samtliga leverantörer.</li>
                <li>20 dagars upphandlad kredit på startorder.</li>
                <li>Installation</li>
                <li>Och mycket mer…</li>
              </ul>
            </StoreConcept>
            <StoreConcept
              style={{
                paddingTop: 40,
                borderTop: '0.5px solid rgba(255, 255, 255, 0.6)',
              }}
            >
              <MdOutlineStore size="45px" />
              <h4>Befintlig lokal</h4>
              <h5>4.990kr per månad</h5>
              <p>
                24Food konceptet går givetvis att implementera i befintliga
                butiker. Faktum är att det många gånger kan vara fördelaktigare
                med just en befintlig lokal. Du behöver inte söka bygglov och
                har större möjlighet till anpassning av lokal, 24Food kan i en
                befintlig lokal sätta upp vårt system så snabbt som på 2 veckor
                efter påskrivet kontrakt. Det kan också vara så att det många
                gånger är billigare med en befintlig lokal.
                <br />
                <br />
                24Food hjälper dig som handlare med inköp av utrusning till ett
                fördelaktigt pris. När du startar en 24Food i en befintlig butik
                får du precis samma förmåner som vid hyra av butik. Att göra om
                en bemannad befintlig butik till obemannad är ____HÄR SAKNAS DET
                TEXT I DOKUMENTET!____
              </p>
              <ul>
                <li>
                  TVR mobile kamerasystem. Möjlighet att övervaka från mobil och
                  dator i realtid. Samt att larma och spela in. Lagring tre
                  dagar i molnet. Fyra kameror och inspelare. Tre kameror
                  inomhus och en utomhus.
                </li>
                <li>Hårdvara för dörröppning via APP.</li>
                <li>Hårdvara för öppning med tagg och passerkort.</li>
                <li>
                  Kassa hårdvara med tillhörande skattedosa och korterminal.{' '}
                </li>
                <li>Mjukvara kassasystem.</li>
                <li>Glassbox valfri storlek.</li>
                <li>
                  App med push notiser till kunder, varje enskild handlare har
                  en egen app sida där denna kan ladda upp information och
                  erbjudanden, möjlighet att lägga upp butikens alla produkter i
                  appen, möjlighet för EU och Schengen medborgare att skapa
                  konto utan svenskt bankID (kontot verifieras inom 2 minuter
                  från vårt kontor), möjlighet för kund att reklamera och
                  kontakta handlaren på dennes egna app sida, unik möjlighet att
                  köpa tobak och läkemedel i appen.{' '}
                </li>
                <li>Dygnet runt support för kund och handlare. </li>
                <li>Upphandlade rabatter hos samtliga leverantörer.</li>
                <li>20 dagars upphandlad kredit på startorder.</li>
                <li>Installation</li>
                <li>Och mycket mer… </li>
              </ul>
            </StoreConcept>
            <StoreConcept
              style={{
                marginRight: 0,
                paddingRight: 0,
                paddingTop: 40,
                paddingLeft: 40,
                border: 0,
                borderTop: '0.5px solid rgba(255, 255, 255, 0.6)',
              }}
            >
              <BiUserCircle size="45px" />
              <h4>Bemannad butik</h4>
              <h5>4.990kr per månad</h5>
              <p>
                24Food konceptet går givetvis att implementera i redan bemannade
                butiker. Att utöka servicen i matbutiken med fler öppna timmar
                ökar både försäljningen och konkurrenskraftigheten. När du
                startar en 24Food i en befintlig butik får du precis samma
                förmåner som vid hyra av butik.
                <br />
                <br />
                Att göra om en bemannad befintlig butik till obemannad är inga
                problem. Med ökade utgifter för mindre handlare och butiker kan
                komplementet med obemannat vara räddningen! Vi kan modifiera din
                butik så att en obemannad kassa är tillgänglig dygnet runt
                samtidigt som vi kan bistå med expertis och renoveringar för
                inlåsning av tobak, läkemedel osv. 24Food kedjan har genom stora
                inköp ofta bättre leverantörspriser än de flesta bemannade
                matbutiker.
              </p>
              <ul>
                <li>
                  TVR mobile kamerasystem. Möjlighet att övervaka från mobil och
                  dator i realtid. Samt att larma och spela in. Lagring tre
                  dagar i molnet. Fyra kameror och inspelare. Tre kameror
                  inomhus och en utomhus.
                </li>
                <li>Hårdvara för dörröppning via APP.</li>
                <li>Hårdvara för öppning med tagg och passerkort.</li>
                <li>
                  Kassa hårdvara med tillhörande skattedosa och korterminal.{' '}
                </li>
                <li>Mjukvara kassasystem.</li>
                <li>Glassbox valfri storlek.</li>
                <li>
                  App med push notiser till kunder, varje enskild handlare har
                  en egen app sida där denna kan ladda upp information och
                  erbjudanden, möjlighet att lägga upp butikens alla produkter i
                  appen, möjlighet för EU och Schengen medborgare att skapa
                  konto utan svenskt bankID (kontot verifieras inom 2 minuter
                  från vårt kontor), möjlighet för kund att reklamera och
                  kontakta handlaren på dennes egna app sida, unik möjlighet att
                  köpa tobak och läkemedel i appen.{' '}
                </li>
                <li>Dygnet runt support för kund och handlare. </li>
                <li>Upphandlade rabatter hos samtliga leverantörer.</li>
                <li>20 dagars upphandlad kredit på startorder.</li>
                <li>Installation</li>
                <li>Och mycket mer… </li>
              </ul>
            </StoreConcept>
          </StoreConceptHolder>
        </StyledMainTextHolder>
      </div>
    </PageAnchor>
  )
}

const ListItem = ({
  title,
  text,
  icon,
}: {
  title?: string
  text: string
  icon: any
}) => {
  return (
    <ItemHolder>
      {icon}
      <div>
        <strong>{title}</strong>
        <p>{text}</p>
      </div>
    </ItemHolder>
  )
}

const HowItemHolder = styled(motion.ul)`
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
`

const HowItem = styled(motion.li)`
  font-family: Glasgow;
  font-weight: 500;
  color: #fff;
  text-align: center;
  opacity: 0;
  text-align: left;
  width: 100%;

  @media (min-width: 769px) {
    width: 33%;
  }
`

const ItemHolder = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 40px;

  svg {
    margin-right: 20px;

    @media (min-width: 769px) {
      margin: 0 20px;
    }
  }

  p {
    font-size: 18px;
    line-height: 24px;
    margin: 8px 0;

    @media (min-width: 769px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  > div {
    width: 100%;
  }
`

const StyledMainTextHolder = styled(MainTextHolder)`
  position: relative;
  z-index: 4;
  margin: 0 auto;

  > p {
    font-size: 22px;
    line-height: 28px;
    text-align: left;
  }
`

const StoreOpeningSteps = styled.ol`
  align-self: center;
  text-align: left;
  margin: 40px auto 0;
  max-width: 650px;

  li {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }
`

const StoreConceptHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`

const StoreConcept = styled.div`
  @media (min-width: 769px) {
    width: calc(50% - 42px);
    padding-right: 30px;
    border-right: 0.5px solid rgba(255, 255, 255, 0.6);
    padding-bottom: 40px;
  }

  img {
    margin-top: 40px;

    @media (min-width: 769px) {
      margin-top: 0;
    }
  }

  h4 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  p {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
  }

  ul {
    list-style: disc;

    li {
      margin: 0 0 10px 20px;
      text-align: left;
      font-size: 14px;
      line-height: 18px;
    }
  }
`
