import React from 'react'
import styled from 'styled-components'

import { MainTextHolder } from './Utils'
import PageAnchor from './PageAnchor'

type StoreProps = {
  media: [
    {
      name: string
      image: string
      text: string
      link: string
      time: string
    }
  ]
}

export default class Media extends React.Component<StoreProps> {
  static defaultProps = {
    media: [
      {
        name:
          '16-åringens kedja växer - vill ta konceptet från Närke till Norrbotten',
        text: 'Artikel i Dagligvarunytt',
        link:
          'https://www.dagligvarunytt.se/i-butik/etablering/16-aringens-kedja-vaxer-vill-ta-konceptet-fran-narke-till-norrbotten/',
        time: '2020-10-29',
      },
      {
        name: 'Bygglov klart för ny mataffär i Hällabrottet',
        text: 'Artikel i Sydnärkenytt',
        link:
          'https://www.sydnarkenytt.se/kumla/artikel/haellabrottet-kan-fa-mataffaer',
        time: '2020-10-29',
      },
      {
        name: 'Alltid öppet i Äspenäset',
        text: 'Artikel i Skaraborgs Allehanda (Plus, betalvägg)',
        link: 'https://www.sla.se/2020/07/22/alltid-oppet-i-aspenaset/',
        time: '2020-07-22',
      },
      {
        name: '15-åringen har startat flera butiker: "Kamprad en förebild"',
        text: 'Inslag i TV4 Örebro',
        link:
          'https://www.tv4.se/efter-fem/klipp/15-%C3%A5ringen-har-startat-flera-butiker-kamprad-en-f%C3%B6rebild-12652590',
        time: '2020-05-08',
      },
      {
        name: '15-årige Jack startade eget företag på fritiden',
        text: 'Artikel i Expressen',
        link:
          'https://www.expressen.se/dinapengar/15-arige-jack-startade-eget-foretag-pa-fritiden/',
        time: '2020-05-03',
      },
      {
        name: '15-åring satsar på egen matbutik',
        text: 'Artikel i Jordbruksaktuellt',
        link:
          'https://www.ja.se/artikel/2225822/15-ring-satsar-p-egen-matbutik.html',
        time: '2020-04-17',
      },
      {
        name: '15-årige Jack driver obemannad butik',
        text: 'Radioinslag i Sveriges Radio P4 Örebro',
        link:
          'https://sverigesradio.se/sida/artikel.aspx?programid=159&artikel=7466375',
        time: '2020-05-05',
      },
      {
        name: '15-årig entreprenör öppnade sina andra butik',
        text: 'Artikel i Fri Köpenskap',
        link:
          'https://www.fri-kopenskap.se/article/view/718367/15arig_entreprenor_oppnade_sina_andra_butik',
        time: '2020-05-12',
      },
      {
        name: '15-årige Jack öppnar två obemannade matbutiker på landsbygden',
        text: 'Artikel i Land',
        link:
          'https://www.land.se/landkoll/jack-berggren-obemannade-butiker-24-food/',
        time: '2020-04-21',
      },
      {
        name: 'Här är nya matbutiken - utanför Lanna Lodge: "Gick fort"',
        text: 'Artikel i NA (Premium, betalvägg)',
        link:
          'https://www.na.se/logga-in/matbutik-dok-upp-vid-golfbana-roligt-med-entreprenorer-sa-han-fick-chansen',
        time: '2020-05-11',
      },
    ],
  }

  render() {
    const arrMedia = this.props.media.sort((a, b) =>
      Date.parse(a.time) < Date.parse(b.time) ? 1 : -1
    )

    return (
      <PageAnchor id="media">
        <StyledMainTextHolder>
          <h3>Media</h3>
          <ul style={{ marginTop: 40 }}>
            {arrMedia.map((media, key) => {
              return (
                <MediaHolder key={key}>
                  <div>
                    <a
                      href={media.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h5>{media.name}</h5>
                    </a>
                    <span>
                      {media.time} - {media.text}
                    </span>
                  </div>
                </MediaHolder>
              )
            })}
          </ul>
        </StyledMainTextHolder>
      </PageAnchor>
    )
  }
}

const StyledMainTextHolder = styled(MainTextHolder)`
  position: relative;
  margin: 0 auto;

  a > h5 {
    text-decoration: underline;
    font-size: 22px;
    line-height: 28px;
  }

  p {
    font-size: 20px;
    line-height: 26px;
    text-align: left;
  }
`

const MediaHolder = styled.li`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  iframe {
    width: 100%;

    @media (min-width: 769px) {
      width: auto;
    }
  }

  & > div {
    text-align: left;

    h4 {
      font-size: 18px;
      line-height: 24px;
      margin-top: 20px;

      @media (min-width: 769px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  @media (min-width: 769px) {
    flex-direction: row;
    align-items: center;

    & > div {
      margin-left: 40px;

      h4 {
        margin-top: 0;
      }
    }
  }
`
