import React from 'react'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'
import { v4 as uuid } from 'uuid'

import { scrollToDiv } from './Utils'

type MenuItemProps = {
  menuItems: [
    {
      title: string
      link: string
      children: [{ title: string; link: string }]
    }
  ]
}

export default class MenuItems extends React.Component<MenuItemProps> {
  static defaultProps = {
    menuItems: [
      {
        title: 'Så här handlar du',
        link: MenuItems.create24FoodWebsiteLink('/#shop'),
        children: [],
      },
      {
        title: 'Om oss',
        link: MenuItems.create24FoodWebsiteLink('/#about'),
        children: [],
      },
      {
        title: 'Butiker',
        link: MenuItems.create24FoodWebsiteLink('/#stores'),
        children: [],
      },
      {
        title: 'Samarbete',
        link: MenuItems.create24FoodWebsiteLink('/#partner'),
        children: [],
      },
      {
        title: 'Franchise',
        link: MenuItems.create24FoodWebsiteLink('/#franchise'),
        children: [],
      },
      {
        title: 'Media',
        link: MenuItems.create24FoodWebsiteLink('/#media'),
        children: [],
      },
    ],
  }

  static create24FoodWebsiteLink(path: string) {
    return window.location.origin + path
  }

  navigate(link: string) {
    const linkUrl = new URL(link)
    const isSameOrigin = window.location.origin === linkUrl.origin
    const alreadyOnPath = window.location.pathname === linkUrl.pathname
    if (isSameOrigin && alreadyOnPath) {
      scrollToDiv(linkUrl.hash.substring(1), 0)
    } else {
      window.location.href = link
    }
  }

  render() {
    return (
      <ul>
        {this.props.menuItems.map(page => {
          return (
            <React.Fragment key={uuid()}>
              <StyledLi key={uuid()}>
                <button
                  onClick={() => {
                    this.navigate(page.link)
                  }}
                >
                  {page.title}
                </button>
              </StyledLi>
              {page.children.map(page => (
                <MediaQuery maxDeviceWidth={768} key={uuid()}>
                  <StyledSubLi>
                    <button onClick={() => scrollToDiv(page.link)}>
                      {page.title}
                    </button>
                  </StyledSubLi>
                </MediaQuery>
              ))}
            </React.Fragment>
          )
        })}
      </ul>
    )
  }
}

const StyledLi = styled.li`
  margin-right: 16px;
  margin-bottom: 16px;
  white-space: nowrap;

  button {
    font-family: Glasgow;
    font-size: 16px;
    color: #fff;
    letter-spacing: 0.8px;
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;

    @media (min-width: 769px) {
      font-size: 20px;
    }
  }

  @media (min-width: 769px) {
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const StyledSubLi = styled(StyledLi)`
  margin-left: 20px;
  margin-bottom: 8px;

  button {
    text-transform: none;
  }

  :first-child {
    margin-top: -10px;
  }
`
