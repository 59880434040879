import React from 'react'
import styled from 'styled-components'

import logo from '../img/24food-logo.png'
import appstoreicon from '../img/appstoreicon.svg'
import googleplayicon from '../img/googleplayicon.png'

export default function Footer() {
  return (
    <FooterDivHolder>
      <FooterDiv>
        <FooterLeft>
          <a href="#top">
            <img alt="Logo" src={logo} style={{ width: 150 }} />
            <p>
              Vår vision är att finnas där vi behövs. Vi har ett färdigt koncept
              utvecklat, Butik, inredning, kassasystem, leverantörer och mycket
              annat.
            </p>
          </a>
          <StoreIcons>
            <a href="https://apps.apple.com/au/app/24-food/id1499978783?l=sv">
              <img
                src={appstoreicon}
                alt="Download on App Store"
                style={{ height: 46, marginRight: 10 }}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.it.food24&hl=en_US">
              <img
                src={googleplayicon}
                alt="Download on Google Play"
                style={{ height: 46 }}
              />
            </a>
          </StoreIcons>
        </FooterLeft>
        <FooterRight>
          <Uppercase>24food</Uppercase>
          Dialoggatan 12B | 703 74 Örebro
          <br />
          <a href="mailto:info@24food.se">info@24food.se</a>
          <br />
          <br />
          <a
            href="/integritetspolicy"
            style={{ color: '#f7ac00', cursor: 'pointer' }}
          >
            Personuppgiftspolicy
          </a>
        </FooterRight>
        <p></p>
      </FooterDiv>
    </FooterDivHolder>
  )
}

const StoreIcons = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`

const FooterDivHolder = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
`

const FooterDiv = styled.div`
  max-width: 980px;
  padding: 40px 0;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media (min-width: 769px) {
    flex-direction: row;
    padding: 40px;
  }
`

const FooterLeft = styled.div`
  width: 80%;
  text-align: left;
  margin-bottom: 40px;

  @media (min-width: 769px) {
    width: 50%;
    margin-bottom: 0;
  }
`

const FooterRight = styled.div`
  width: 80%;
  text-align: right;

  @media (min-width: 769px) {
    width: 50%;
  }
`

const Uppercase = styled.span`
  text-transform: uppercase;
  display: block;
  letter-spacing: 0.05em;
`
