import React from 'react'
import styled from 'styled-components'
import franchisebanner from '../img/franchisebanner.png'
import PageAnchor from './PageAnchor'
import { MainTextHolder } from './Utils'

import ekebyalmby from '../img/stores/ekebyalmby.jpeg';
import eldtomta from '../img/stores/eldtomta.jpeg';
import vena from '../img/stores/vena.png';
import hallabrottet from '../img/stores/hallabrottet.jpeg';
import lanna from '../img/stores/lanna.jpeg';
import karlsborg from '../img/stores/karlsborg.jpg';
import morko from '../img/stores/morko.jpg';
import vimmerby from '../img/stores/vimmerby.jpeg';
import sjulsmark from '../img/stores/sjulsmark.jpg';
import osteraker from '../img/stores/osteraker.jpg';
import husby from '../img/stores/husby.jpg';
import vasterljung from '../img/stores/vasterljung.jpeg';
import froseke from '../img/stores/froseke.jpg';
import stockfallet from '../img/stores/stockfallet.jpg';
import vase from '../img/stores/vase.png';
import forssjo from '../img/stores/forssjo.png';
import klovertrask from '../img/stores/klovertrask.png';
import hjortkvarn from '../img/stores/hjortkvarn.jpg';
import lovskar from '../img/stores/lovskar.jpeg';
import halanda from '../img/stores/halanda.jpeg';
import vallby from '../img/stores/vallby.png';
import hallsta from '../img/stores/hallsta.png';
import furuvik from '../img/stores/furuvik.jpeg';
import skee from '../img/stores/skee.png';
import stocke from '../img/stores/stocke.jpeg';
import olmbrotorp from '../img/stores/olmbrotorp.jpg';
import knivsta from '../img/stores/knivsta.jpg';
import bredsand from '../img/stores/bredsand.jpg';
import asljunga from '../img/stores/asljunga.jpg';
import bensbyn from '../img/stores/bensbyn.jpeg';
import sonstorp from '../img/stores/sonstorp.JPG';
import malungsfors from '../img/stores/malungsfors.jpg';
import hallevik from '../img/stores/hallevik.jpg';
import strandvallenhallevik from '../img/stores/strandvallenhallevik.jpg';
import vagnharad from '../img/stores/vagnharad.jpg';
import kornsjo from '../img/stores/kornsjo.jpg';
import veckholm from '../img/stores/veckholm.jpeg';
import haga from '../img/stores/haga.jpeg';
import unbyn from '../img/stores/unbyn.jpeg';
import engesberg from '../img/stores/engesberg.png';

type StoreProps = {
  stores: [
    {
      name: string
      text: string
      image: string
      hours: string
      googleMapsLocation: string
      franchise: boolean
    }
  ]
}

export default class Stores extends React.Component<StoreProps> {
  static defaultProps = {
    stores: [
      {
        "name": "Örebro - Ekeby-Almby",
        "image": ekebyalmby,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Ekeby-Almby/@59.2595996,15.346435,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "Butiken i Ekeby Almby är vår första butik. Vi hoppas att du gillar den! Vi har plats för omkring 550 olika produkter och fler är på väg.",
      },
      {
        "name": "Grödinge - Eldtomta",
        "image": eldtomta,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Eldtomta/@59.10560866882889,17.730982967341248,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Vena",
        "image": vena,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Vena/@57.52184220162991,15.968151248450567,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Kumla - Hällabrottet",
        "image": hallabrottet,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Hällabrottet/@59.116111,15.200278,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Vintrosa - Lanna Lodge",
        "image": lanna,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Lanna Lodge/@59.240014,14.904967,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "Butiken i Lanna är vår andra butik och är av vår större modell. Här finns det plats för omkring 1000 produkter.",
      },
      {
        "name": "Karlsborg - Karlsborg",
        "image": karlsborg,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Karlsborg/@58.548036,14.502617,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "24Food Karlsborg är vår första franschisebutik. Det är en standardmodell och innehåller ca. 550 artiklar.",
      },
      {
        "name": "Mörkö",
        "image": morko,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Mörkö/@58.993037,17.652472,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "24Food Mörkö Handelsbod är en franchisebutik med ca. 550 artiklar.",
      },
      {
        "name": "Vimmerby - Vimmerby Camping",
        "image": vimmerby,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Vimmerby Camping/@57.662113,15.900448,17z",
        "hours": "",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Sjulsmark",
        "image": sjulsmark,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Sjulsmark/@65.516978,21.496114,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Österåker",
        "image": osteraker,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Österåker/@59.124369,15.987452,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Norrköping - Göta kanal, Västra Husby",
        "image": husby,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Göta kanal, Västra Husby/@58.49,16.174444,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Västerljung",
        "image": vasterljung,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Västerljung/@58.920556,17.434444,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Fröseke - Fröseke Livs",
        "image": froseke,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Fröseke Livs/@56.9487238,15.7913187,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Stockfallet",
        "image": stockfallet,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Stockfallet/@59.411667,13.546667,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Väse",
        "image": vase,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Väse/@59.382222,13.850556,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Forssjö",
        "image": forssjo,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Forssjö/@58.950833,16.296389,17z",
        "hours": "",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Klöverträsk",
        "image": klovertrask,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Klöverträsk/@65.633831,21.403874,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Hjortkvarn",
        "image": hjortkvarn,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Hjortkvarn/@58.894167,15.431944,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Luleå - Lövskär",
        "image": lovskar,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Lövskär/@65.541912,22.370917,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Hålanda",
        "image": halanda,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Hålanda/@58.048889,12.288333,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Eskilstuna - Vallby",
        "image": vallby,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Vallby/@59.43686936077263,16.524092363847053,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Hållsta",
        "image": hallsta,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Hållsta/@59.291562,16.450346,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Furuvik",
        "image": furuvik,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Furuvik/@60.650791214024196,17.331408686741863,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Skee",
        "image": skee,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Skee/@58.92796496943074,11.273829967259003,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Umeå - Stöcke",
        "image": stocke,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Stöcke/@63.74161644265518,20.228191341193565,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Dyltabruk - Ölmbrotorp",
        "image": olmbrotorp,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Ölmbrotorp/@59.38826718186919,15.243880919574396,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Knivsta - Strömsbro, Knivsta",
        "image": knivsta,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Strömsbro, Knivsta/@59.75263310804808,18.04476077713344,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Enköping - Bredsand",
        "image": bredsand,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Bredsand/@59.592708290645426,17.074771210555515,17z",
        "hours": "",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Åsljunga",
        "image": asljunga,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Åsljunga/@56.30704407866967,13.367242545524917,17z",
        "hours": "",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Luleå - Bensbyn",
        "image": bensbyn,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Bensbyn/@65.642306,22.235831,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Finspång - Sonstorp",
        "image": sonstorp,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Sonstorp/@58.72388990964087,15.632518329346588,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Malungsfors",
        "image": malungsfors,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Malungsfors/@60.7346302,13.5576731,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Sölvesborg - Hällevik",
        "image": hallevik,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Hällevik/@56.01278757200513,14.700244901355232,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Sölvesborg - Strandvallen, Hällevik",
        "image": strandvallenhallevik,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Strandvallen, Hällevik/@56.01151605964233,14.718915542914196,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Vagnhärad",
        "image": vagnharad,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Vagnhärad/@58.946131560591,17.48720372673597,17z",
        "hours": "",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Kornsjø",
        "image": kornsjo,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Kornsjö/@58.93358,11.67167,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Veckholm",
        "image": veckholm,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Veckholm/@59.52064233230225,17.31656615867702,17z",
        "hours": "",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Norrköping - Haga",
        "image": haga,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Haga/@58.5976526082105,16.155105898823447,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Boden - Unbyn",
        "image": unbyn,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Unbyn/@65.7128890359211,21.76078105654104,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      },
      {
        "name": "Gävle - Engesberg",
        "image": engesberg,
        "googleMapsLocation": "https://www.google.com/maps/search/24Food+Engesberg/@60.7319235,17.2911599,17z",
        "hours": "Alltid öppet!",
        "franchise": false,
        "text": "",
      }
    ],
  }

  render() {
    return (
      <PageAnchor id="stores">
        <StyledMainTextHolder>
          <h3>Några av våra butiker</h3>
          <div style={{ position: 'relative' }}>
            <StoresConatiner style={{ marginTop: 40 }}>
              {this.props.stores.map((store, key) => (
                <StoreHolder key={key}>
                  <StoreImageContainer>
                    {store.franchise && (
                      <FranchiseBanner src={franchisebanner} />
                    )}
                    <StoreImage src={store.image} alt={store.name} />
                  </StoreImageContainer>
                  <div>
                    <h5>{store.name}</h5>
                    <p>{store.text}</p>
                    <p>
                      Öppettider: {store.hours}
                      {store.googleMapsLocation && (
                        <>
                          <br />
                          Adress:{' '}
                          <a
                            href={store.googleMapsLocation}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Klicka för karta
                          </a>
                        </>
                      )}
                    </p>
                  </div>
                </StoreHolder>
              )
              )}
            </StoresConatiner>
          </div>
        </StyledMainTextHolder>
      </PageAnchor>
    )
  }
}

const StyledMainTextHolder = styled(MainTextHolder)`
  position: relative;
  z-index: 4;
  margin: 0 auto;

  p {
    font-size: 22px;
    line-height: 28px;
    text-align: left;
  }
`

const StoresConatiner = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const StoreHolder = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;

  & > div {
    text-align: left;

    h5 {
      font-size: 24px;
      margin-top: 20px;
    }
  }

  @media (min-width: 576px) {
    flex-direction: column;

    & > div {
      h4 {
        margin-top: 0;
      }
    }
  }
`

const StoreImageContainer = styled.div`
  position: relative;
  width: 100%;
`

const FranchiseBanner = styled.img`
  width: 210px !important;
  position: absolute;
  left: -11px;
  top: -9px;
`

const StoreImage = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;
`
