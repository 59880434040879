import React from 'react'
import styled from 'styled-components'

function RequestAccountDeletion() {
  return (
    <Container>
      <h1>Ta bort mitt konto</h1>
      <br />
      Vill du ta bort ditt användarkonto från vår mobilapp? Vi förstår att det
      finns olika skäl till varför du kanske vill göra det, och vi respekterar
      din önskan.
      <br />
      <br />
      För att begära borttagning av ditt konto, vänligen följ dessa steg:
      <ol>
        <li>Skicka ett e-postmeddelande till info@24food.se.</li>
        <li>I ämnesraden, ange "Borttagning av användarkonto". </li>
        <li>
          I själva meddelandet, vänligen berätta om du har skapat ett konto med
          telefonnummer eller om du har loggat in genom att använda BankID.
        </li>
      </ol>
      <br />
      <br />
      Vårt supportteam kommer att behandla din förfrågan så snabbt som möjligt.
      Observera att efter att ditt konto har tagits bort kommer all din
      information att raderas.
      <br />
      <br />
      Tack för att du har använt vår tjänst, och vi beklagar om du har stött på
      problem.
    </Container>
  )
}

export default RequestAccountDeletion

const Container = styled.div`
  box-sizing: border-box;
  max-width: 900px;
  margin: 100px auto;
  padding: 80px;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);

  @media (max-width: 900px) {
    margin: 80px auto 0;
    padding: 20px;
  }
  @media (max-width: 769px) {
    margin: 60px auto 0;
  }
`
