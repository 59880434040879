import React from 'react'

interface Props {
  id: string
  children: React.ReactNode
  offsetTop?: number
}

function PageAnchor({ children, id, offsetTop = 80 }: Props) {
  return (
    <div style={{ paddingTop: offsetTop, marginTop: -offsetTop }} id={id}>
      {children}
    </div>
  )
}

export default PageAnchor
