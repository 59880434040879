import React from 'react'
import styled from 'styled-components'

import nachos from '../img/nachos.jpg'

import { MainTextHolder } from './Utils'
import PageAnchor from './PageAnchor'

export default function About() {
  return (
    <PageAnchor id="about">
      <AboutHolder>
        <StyledMainTextHolder>
          <h3>Om oss</h3>
          <p>
            24Food är matbutiken som alltid har öppet och skall finnas nära dig.
            <br />
            <br />
            24Food är Sverige nya butikskedja med helt obemannade butiker som
            kan placeras nästan var som helst. Kanske saknas underlag för en
            stor butik? Då kan en av våra butiker vara det perfekta
            alternativet.
            <br />
            24Food kommer initialt driva butiker i egen regi och då främst i och
            omkring Örebro. Vi satsar på att ha 60 - 80 matbutiker runtom i
            landet under 2024.
            <br />
            <br />
            Vi på 24Food bjuder även in externa aktörer. Vi delar gärna med oss
            av vår kunskap och våra stödfunktioner och har färdiga butiksmoduler
            klara för leverans. Från idé till första sålda vara kan vara så lite
            som ett par veckor bort!
            <br />
            Man kan välja mellan att hyra en butik eller att köpa en, hör av Er
            till oss så diskuterar vi fram den lösning som passar just Er.
            <br />
            <br />
            Alla är välkomna att samarbeta med oss! Privatpersoner som vill
            prova att driva butik, samfälligheter som vill ha en matbutik i sitt
            område eller kommuner som vill hjälpa områden, orter eller
            stadsdelar som saknar en matbutik.
            <br />
            <strong>Välkomna till oss på 24Food!</strong>
            <br />
            <br />
            Jack Berggren, grundare, ägare och operativ chef på 24Food AB
          </p>
        </StyledMainTextHolder>
      </AboutHolder>
    </PageAnchor>
  )
}

const AboutHolder = styled.div`
  background-image: url(${nachos});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 3;
  }
`

const StyledMainTextHolder = styled(MainTextHolder)`
  position: relative;
  z-index: 4;
  margin: 0 auto;

  p {
    font-size: 22px;
    line-height: 28px;
    text-align: left;
  }
`
