import React from 'react'

import Header from './components/Header'
import Footer from './components/Footer'
import Hero from './components/Hero'
import How from './components/How'
import About from './components/About'
import Partner from './components/Partner'
import Stores from './components/Stores'
import Media from './components/Media'
import Franchise from './components/Franchise'
import GDPR from './components/GDPR'
import RequestAccountDeletion from './components/RequestAccountDeletion'

export default class App extends React.Component {
  showGDPR = window.location.href.includes('integritetspolicy')
  showRequestAccountDeletion = window.location.href.includes('ta-bort-konto')

  render() {
    if (this.showRequestAccountDeletion) {
      return (
        <>
          <Header />
          <RequestAccountDeletion />
          <Footer />
        </>
      )
    }

    if (this.showGDPR) {
      return (
        <>
          <Header />
          <GDPR />
          <Footer />
        </>
      )
    }

    return (
      <>
        <Header />
        <Hero />
        <How />
        <About />
        <Stores />
        <Partner />
        <Franchise />
        <Media />
        <Footer />
      </>
    )
  }
}
